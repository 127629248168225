export default {
    computed: {
        headers() {
            return [
                {
                    value: 'name',
                    text: this.$t('labels.name'),
                },
                {
                    value: 'fullAddress',
                    text: this.$t('labels.address'),
                },
                {
                    value: 'subAddress',
                    text: this.$t('labels.address_details'),
                },
            ]
        },
    },
}
