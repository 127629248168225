<template>
    <form-two-col-row>
        <template v-slot:col1>
            <address-autocomplete-input
                :value="address"
                name="addressInput.fullAddress"
                @input="setFullAddress"
                @update:latitude="autocompleteField(latitudeProp, $event)"
                @update:longitude="autocompleteField(longitudeProp, $event)"
                @update:country="autocompleteField(countryProp, $event)"
                @update:countryCode="autocompleteField(countryCodeProp, $event)"
                @update:zipCode="autocompleteField(zipCodeProp, $event)"
                @update:town="autocompleteField(townProp, $event)"
                @update:street="autocompleteField(streetProp, $event)"
                @update:houseNumber="autocompleteField(houseNumberProp, $event)"
                @update:sublocality="autocompleteField(sublocalityProp, $event)"
                @update:placeId="autocompleteField(placeIdProp, $event)"
                @update:administrativeAreaLevel1="
                    autocompleteField(adminArea1Prop, $event)
                "
                @update:administrativeAreaLevel2="
                    autocompleteField(adminArea2Prop, $event)
                "
                @update:administrativeAreaLevel3="
                    autocompleteField(adminArea3Prop, $event)
                "
                @update:administrativeAreaLevel4="
                    autocompleteField(adminArea4Prop, $event)
                "
            ></address-autocomplete-input>
            <v-row no-gutters align="center">
                <v-col>
                    <v-text-field
                        :value="fullAddress"
                        :label="$t('labels.address')"
                        readonly
                        disabled
                    ></v-text-field>
                </v-col>
                <v-col cols="auto">
                    <v-icon
                        v-if="clearable && fullAddress"
                        class="pa-1"
                        @click="clearFields"
                        >close</v-icon
                    >
                </v-col>
            </v-row>
            <v-row>
                <v-col v-show="hasField(houseNumberProp)" cols="6">
                    <text-input
                        v-model="houseNumber"
                        label="labels.street_number"
                        :name="`${name}.houseNumber`"
                    ></text-input>
                </v-col>
                <v-col v-show="hasField(streetProp)" cols="6">
                    <text-input
                        v-model="street"
                        v-validate.immediate="
                            required ? 'required|max:255' : 'max:255'
                        "
                        label="labels.street"
                        counter="255"
                        :name="`${name}.street`"
                        :error-messages="errors.collect(`${name}.street`)"
                        :data-vv-as="$t('labels.street')"
                    ></text-input>
                </v-col>
                <v-col v-show="hasField(townProp)" cols="6">
                    <text-input
                        v-model="town"
                        v-validate.immediate="
                            required ? 'required|max:255' : 'max:255'
                        "
                        label="labels.city"
                        readonly
                        disabled
                        :name="`${name}.town`"
                        :error-messages="errors.collect(`${name}.town`)"
                        :data-vv-as="$t('labels.city')"
                    ></text-input>
                </v-col>
                <v-col v-show="hasField(zipCodeProp)" cols="6">
                    <text-input
                        v-model="zipCode"
                        v-validate.immediate="
                            required ? 'required|max:255' : 'max:255'
                        "
                        label="labels.postcode"
                        :name="`${name}.postCode`"
                        :error-messages="errors.collect(`${name}.postCode`)"
                        :data-vv-as="$t('labels.postcode')"
                    ></text-input>
                </v-col>
                <v-col v-show="hasField(countryProp)" cols="12">
                    <text-input
                        v-model="country"
                        v-validate.immediate="
                            required ? 'required|max:255' : 'max:255'
                        "
                        label="labels.country"
                        readonly
                        disabled
                        :name="`${name}.country`"
                        :error-messages="errors.collect(`${name}.country`)"
                        :data-vv-as="$t('labels.country')"
                    ></text-input>
                </v-col>
            </v-row>
        </template>
        <template v-slot:col2>
            <address-map-input
                style="height: 300px"
                name="addressInput.map"
                only-coords
                :latitude.sync="latitude"
                :longitude.sync="longitude"
            ></address-map-input>
            <v-row>
                <v-col cols="6">
                    <v-text-field
                        v-model="inputLatitude"
                        v-validate.immediate="
                            required ? 'required|decimal:6' : 'decimal:6'
                        "
                        :name="`${name}.latitude`"
                        :label="$t('labels.latitude')"
                        :error-messages="errors.collect(`${name}.latitude`)"
                        :data-vv-as="$t('labels.latitude')"
                    ></v-text-field>
                </v-col>
                <v-col cols="6">
                    <v-text-field
                        v-model="inputLongitude"
                        v-validate.immediate="
                            required ? 'required|decimal:6' : 'decimal:6'
                        "
                        :name="`${name}.longitude`"
                        :label="$t('labels.longitude')"
                        :error-messages="errors.collect(`${name}.longitude`)"
                        :data-vv-as="$t('labels.longitude')"
                    ></v-text-field>
                </v-col>
            </v-row>
        </template>
    </form-two-col-row>
</template>

<script>
import ErrorMsgInputMixin from '@/components/form/Inputs/InputMixins/ErrorMsgInputMixin'
import AddressPropertyHandlerMixin from './mixins/AddressPropertyHandlerMixin'

import FormTwoColRow from '@/components/form/FormTwoColRow'
import TextInput from '@/components/form/Inputs/TextInput'
import AddressAutocompleteInput from '@/components/form/Inputs/google/AddressAutocompleteInput'
import AddressMapInput from '@/components/form/Inputs/google/AddressMapInput'

export default {
    components: {
        FormTwoColRow,
        TextInput,
        AddressAutocompleteInput,
        AddressMapInput,
    },

    mixins: [ErrorMsgInputMixin, AddressPropertyHandlerMixin],

    props: {
        required: Boolean,
        clearable: Boolean,
    },
}
</script>
