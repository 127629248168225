<template>
    <div>
        <form-panel>
            <form-two-col-row>
                <template slot="col1">
                    <text-input
                        v-model="form.name"
                        v-validate="'required|max:255|space'"
                        name="name"
                        label="labels.name"
                        counter="255"
                        :error-messages="errors.collect('name')"
                        :data-vv-as="$t('labels.name')"
                    ></text-input>
                </template>
                <template v-if="!hideInvoiceName" slot="col2">
                    <text-input
                        v-model="form.invoiceName"
                        v-validate="'max:255|space'"
                        name="invoiceName"
                        label="labels.invoice_name"
                        counter="255"
                        :error-messages="errors.collect('invoiceName')"
                        :data-vv-as="$t('labels.invoice_name')"
                    ></text-input>
                </template>
            </form-two-col-row>
            <form-two-col-row>
                <template slot="col1">
                    <text-input
                        v-model="form.phone"
                        v-validate="'required|phonenumber|max:255'"
                        name="phone"
                        label="labels.phone"
                        counter="255"
                        :error-messages="errors.collect('phone')"
                        :data-vv-as="$t('labels.phone')"
                        @blur="form.phone = formatPhoneNumber(form.phone)"
                    ></text-input>
                </template>
            </form-two-col-row>
        </form-panel>
        <form-panel>
            <address-inputs
                v-model="form"
                name="companyAddress"
                required
            ></address-inputs>
            <form-two-col-row>
                <template v-slot:col1>
                    <text-input
                        v-model="form.subAddress"
                        v-validate="
                            form.houseNumber
                                ? 'max:255|space'
                                : 'required|max:255|space'
                        "
                        name="subAddress"
                        label="labels.address_details"
                        counter="255"
                        :error-messages="errors.collect('subAddress')"
                        :data-vv-as="$t('labels.address_details')"
                    ></text-input>
                </template>
                <template v-slot:col2>
                    <text-input
                        v-model="form.comment"
                        v-validate="'max:255|space'"
                        name="comment"
                        label="labels.comment"
                        counter="255"
                        :error-messages="errors.collect('comment')"
                        :data-vv-as="$t('labels.comment')"
                    ></text-input>
                </template>
            </form-two-col-row>
            <div v-if="innerForm" class="d-flex justify-end px-4 pt-4 mt-8">
                <div>
                    <v-btn
                        v-if="form.id"
                        class="mr-4"
                        color="warning"
                        @click="dialog = true"
                    >
                        {{ $t('actions.delete') }}
                    </v-btn>
                    <v-btn
                        v-else
                        variant="secondary"
                        class="mr-3"
                        @click="cancel"
                    >
                        {{ $t('actions.cancel') }}
                    </v-btn>
                    <v-btn
                        color="success"
                        :disabled="!form.name || !form.fullAddress"
                        @click="save"
                    >
                        {{ $t('actions.save') }}
                    </v-btn>
                </div>
                <confirm-modal
                    v-model="dialog"
                    :title="$t('texts.are_you_sure')"
                    @onApprove="deleteAddress"
                ></confirm-modal>
            </div>
        </form-panel>
    </div>
</template>

<script>
import UserAddressesApi from '@/api/RestApi/UserAddresses'
import UserAddressesFormMapper from '@/services/mapper/UserAddressesFormMapper'
import FormPartMixin from '@/components/mixins/FormPartMixin'
import PhoneNumberMixin from '@/components/mixins/PhoneNumberMixin'

import FormPanel from '@/components/form/FormPanel'
import FormTwoColRow from '@/components/form/FormTwoColRow'
import TextInput from '@/components/form/Inputs/TextInput'
import ConfirmModal from '@/components/confirm/ConfirmModal'
import AddressInputs from '@/components/pages/addresses/AddressInputs'

export default {
    components: {
        FormPanel,
        FormTwoColRow,
        TextInput,
        ConfirmModal,
        AddressInputs,
    },
    mixins: [FormPartMixin, PhoneNumberMixin],

    props: {
        innerForm: Boolean,
        hideInvoiceName: Boolean,
        user: {
            type: Object,
            default: () => {
                return {}
            },
        },
    },

    data() {
        return {
            dialog: false,
        }
    },

    watch: {
        user: {
            handler() {
                if (this.user && this.user.id) {
                    this.$set(this.form, 'phone', this.user.phone)
                    this.$set(this.form, 'userProfile', this.user['@id'])
                }
            },
            immediate: true,
        },
        $route() {
            if (this.innerForm) {
                this.$validator.reset()
                this.$set(this, 'form', this.value)
            }
        },
    },

    methods: {
        save() {
            if (!this.form.name || !this.form.fullAddress) return

            if (this.form.id) {
                UserAddressesApi.update(this.form, this.form.id).then(() => {
                    this.$emit('updateList')
                })
            } else {
                this.form.userProfile = this.user['@id']

                const entity = UserAddressesFormMapper.toEntity(this.form)

                UserAddressesApi.create(entity).then(() => {
                    this.$emit('updateList')
                })
            }
        },

        deleteAddress() {
            UserAddressesApi.delete(this.form.id).then(() => {
                this.$emit('updateListAfterDelete')
                this.cancel()
            })
        },
        cancel() {
            this.form = {
                phone: this.user.phone,
                userProfile: this.user['@id'],
            }
            this.$emit('cancel')
        },
    },
}
</script>
