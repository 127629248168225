export default {
    props: {
        value: {
            type: Object,
            default: () => ({}),
        },
        fullAddressProp: {
            type: String,
            default: 'fullAddress',
        },
        latitudeProp: {
            type: String,
            default: 'latitude',
        },
        longitudeProp: {
            type: String,
            default: 'longitude',
        },
        countryProp: {
            type: String,
            default: 'country',
        },
        zipCodeProp: {
            type: String,
            default: 'zipCode',
        },
        townProp: {
            type: String,
            default: 'town',
        },
        streetProp: {
            type: String,
            default: 'street',
        },
        houseNumberProp: {
            type: String,
            default: 'houseNumber',
        },
        sublocalityProp: {
            type: String,
            default: 'sublocality',
        },
        countryCodeProp: {
            type: String,
            default: 'countryCode',
        },
        placeIdProp: {
            type: String,
            default: 'placeId',
        },
        adminArea1Prop: {
            type: String,
            default: 'administrativeAreaLevel1',
        },
        adminArea2Prop: {
            type: String,
            default: 'administrativeAreaLevel2',
        },
        adminArea3Prop: {
            type: String,
            default: 'administrativeAreaLevel3',
        },
        adminArea4Prop: {
            type: String,
            default: 'administrativeAreaLevel4',
        },
    },

    data() {
        return {
            model: {},
            address: null,
        }
    },

    computed: {
        fullAddress: {
            get() {
                return this.value?.[this.fullAddressProp]
            },
        },
        latitude: {
            get() {
                return this.value?.[this.latitudeProp]
            },
            set(val) {
                this.setFloat(this.latitudeProp, val)
            },
        },
        longitude: {
            get() {
                return this.value?.[this.longitudeProp]
            },
            set(val) {
                this.setFloat(this.longitudeProp, val)
            },
        },
        inputLatitude: {
            get() {
                return this.value?.[this.latitudeProp]
            },
            set(val) {
                this.setInputFloat(this.latitudeProp, val)
            },
        },
        inputLongitude: {
            get() {
                return this.value?.[this.longitudeProp]
            },
            set(val) {
                this.setInputFloat(this.longitudeProp, val)
            },
        },
        country: {
            get() {
                return this.value?.[this.countryProp]
            },
            set(val) {
                this.setField(this.countryProp, val)
            },
        },
        zipCode: {
            get() {
                return this.value?.[this.zipCodeProp]
            },
            set(val) {
                this.setField(this.zipCodeProp, val)
            },
        },
        town: {
            get() {
                return this.value?.[this.townProp]
            },
            set(val) {
                this.setField(this.townProp, val)
            },
        },
        street: {
            get() {
                return this.value?.[this.streetProp]
            },
            set(val) {
                this.setField(this.streetProp, val)
            },
        },
        houseNumber: {
            get() {
                return this.value?.[this.houseNumberProp]
            },
            set(val) {
                this.setField(this.houseNumberProp, val)
            },
        },
        sublocality: {
            get() {
                return this.value?.[this.sublocalityProp]
            },
        },
        countryCode: {
            get() {
                return this.value?.[this.countryCodeProp]
            },
        },
        placeId: {
            get() {
                return this.value?.[this.placeIdProp]
            },
        },
        adminArea1: {
            get() {
                return this.value?.[this.adminArea1Prop]
            },
        },
        adminArea2: {
            get() {
                return this.value?.[this.adminArea2Prop]
            },
        },
        adminArea3: {
            get() {
                return this.value?.[this.adminArea3Prop]
            },
        },
        adminArea4: {
            get() {
                return this.value?.[this.adminArea4Prop]
            },
        },
    },

    watch: {
        model: {
            handler() {
                this.$emit('input', this.model)
            },
            immediate: true,
            deep: true,
        },
        value: {
            handler(val) {
                this.$set(this, 'model', val)
            },
            immediate: true,
            deep: true,
        },
        houseNumber() {
            this.setField(this.fullAddressProp, this.getAddressText())
        },
        zipCode() {
            this.setField(this.fullAddressProp, this.getAddressText())
        },
        street() {
            this.setField(this.fullAddressProp, this.getAddressText())
        },
    },

    methods: {
        setFullAddress(address) {
            if (address) {
                this.address = address
                setTimeout(() => {
                    this.address = null
                    this.setField(this.fullAddressProp, this.getAddressText())
                }, 250)
            }
        },
        autocompleteField(field, value) {
            if (this.address !== null && Object.hasOwn(this.model, field)) {
                this.$set(
                    this.model,
                    field,
                    typeof value !== 'number'
                        ? value
                        : parseFloat(parseFloat(value).toFixed(6))
                )
            }
        },
        hasField(field) {
            return Object.hasOwn(this.model, field)
        },
        setField(field, value) {
            if (Object.hasOwn(this.model, field)) {
                this.$set(this.model, field, value)
            }
        },
        setFloat(field, value) {
            if (Object.hasOwn(this.model, field)) {
                this.$set(
                    this.model,
                    field,
                    value === '' || value === null
                        ? null
                        : parseFloat(parseFloat(value).toFixed(6))
                )
            }
        },
        setInputFloat(field, value) {
            if (Object.hasOwn(this.model, field)) {
                this.$set(
                    this.model,
                    field,
                    value === '' || value === null ? null : parseFloat(value)
                )
            }
        },
        clearField(field) {
            if (Object.hasOwn(this.model, field)) {
                this.$set(this.model, field, null)
            }
        },
        clearFields() {
            this.clearField(this.latitudeProp)
            this.clearField(this.longitudeProp)
            this.clearField(this.countryProp)
            this.clearField(this.zipCodeProp)
            this.clearField(this.townProp)
            this.clearField(this.streetProp)
            this.clearField(this.houseNumberProp)
            this.clearField(this.sublocalityProp)
            this.clearField(this.countryCodeProp)
            this.clearField(this.placeIdProp)
            this.clearField(this.adminArea1Prop)
            this.clearField(this.adminArea2Prop)
            this.clearField(this.adminArea3Prop)
            this.clearField(this.adminArea4Prop)
            this.$nextTick(() => {
                this.clearField(this.fullAddressProp)
                this.$emit('clear')
            })
        },
        getAddressText() {
            return this.$t('address_format', {
                house_number: this.houseNumber,
                street: this.street,
                town: this.town,
                zip_code: this.zipCode,
                country_code: this.countryCode,
                country: this.country,
            }).trim()
        },
        changeFulladdress(val, old) {
            if (!this.address && val && old) {
                this.setField(
                    this.fullAddressProp,
                    this.fullAddress.replace?.(old, val) || null
                )
            }
        },
    },
}
